import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Thanks = () => (
  <div className={`bg-gray-700`}>
    <Layout>
      <SEO title="Thank You" />
      <section className={`container px-5 mx-auto mb-20`}>
        <div className={`flex flex-wrap -mx-2 text-white`}>
          {/* <!-- intro-content --> */}
          <div className={`w-full px-10 sm:mx-16 `}>
            <h1
              className={`mb-2 font-hairline  md:border-b-0 lg:border-b-2 text-2xl md:text-3xl lg:text-4xl sm:text-center sm:leading-none sm:pb-3 xl:leading-tight lg:text-left`}
            >
              Thank You
            </h1>
            <div className={``}>
              <p>
                Your enquiry has been received. I will try to get back to you as
                soon as possible.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  </div>
)

export default Thanks
